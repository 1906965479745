<style lang="scss">
@import "~@/assets/css/var";

.num {
    color: #ffffff;
    background: #ff0000;
    border-radius: 50%;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    position: absolute;
    top: 0;
}

.active_num {
    position: relative;
}

.el-radio-button--mini .el-radio-button__inner {
    padding: 7px 20px;
}
</style>
<template>
    <div class="index-page" style="height: calc(100vh - 100px);overflow: hidden;">
        <img src="@/assets/img/banner.png" alt="banner">

        <div class="my-notice" style="height: calc(100% - 212px);overflow: hidden;">
            <p class="my-notice__desc">个人中心 - 消息中心</p>

            <div class="panel" style="padding-bottom: 20px;height: 100%;box-sizing: border-box;" v-loading="loading">
                <div class="my-notice__title" style="display: flex;align-items: center;">
                    <el-radio-group v-model="type" @input="setRadio">
                        <el-radio-button label="notice" class="active_num">
                            公告通知
                            <span class="num"
                                v-if="$store.state.isReadObj.helpNoticeNumber">{{ $store.state.isReadObj.helpNoticeNumber
                                }}</span>
                        </el-radio-button>
                        <el-radio-button label="personal" class="active_num">
                            个人消息
                            <span class="num"
                                v-if="$store.state.isReadObj.sysSendNumber">{{ $store.state.isReadObj.sysSendNumber
                                }}</span>
                        </el-radio-button>
                    </el-radio-group>
                    <div style="    display: flex;align-items: center;">
                        <el-input style="margin: 0px 6px 0 20px;" v-model="searchText" placeholder="请输入内容"
                            clearable></el-input>
                        <el-button type="primary" @click="search">搜索</el-button>
                    </div>
                    <div class="my-notice__operate" v-if="type !== 'notice'">
                        <el-button type="text" @click="setReadAll">全部设置已读</el-button>
                    </div>
                </div>

                <div class="my-notice__panel"
                    style="padding-bottom: 0;margin-bottom: 0;height: 86%;box-sizing: border-box;">
                    <div style="height: 85%;overflow: auto;">
                        <div class="my-notice__panel-item" v-for="item in list" :key="item.id">
                            <template v-if="type === 'notice'">
                                <div class="head" style="justify-content: space-between;">
                                    <span class="title">{{ item.createdAt }}</span>
                                    <span class="title" v-if="item.isRead === '0'"
                                        style="color: #ff0000;margin-right: 10px;font-size: 12px;">未读</span>
                                </div>
                                <div class="body">{{ item.content }}</div>
                                <div class="line"></div>
                            </template>
                            <template v-else>
                                <div class="head" style="justify-content: space-between;">
                                    <div class="title">
                                        <span class="title">{{ item.createdAt }}</span>
                                        <span class="title" style="margin-left: 20px;">{{ item.sourceData }}</span>
                                    </div>
                                    <span class="title" v-if="item.readFlag === '0'"
                                        style="color: #ff0000;margin-right: 10px;font-size: 12px;">未读</span>
                                </div>
                                <div class="body">{{ item.sendContent }}</div>
                                <div class="line"></div>
                            </template>

                        </div>
                    </div>

                    <div class="page">
                        <el-pagination layout="prev, pager, next, total" :total="total" :page-size="page.pageSize"
                            @current-change="pageChange"></el-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'message',
            pageName: '消息中心',

            loading: false,
            list: [],
            total: 0,
            page: {
                pageNo: 1,
                pageSize: 10,
            },
            type: 'notice',
            searchText: "",   //搜索内容
        }
    },
    created() {
        this.$help.socket.send(this)

        this.init()
    },
    methods: {
        search() {
            this.init()
        },
        setRadio() {
            this.searchText = ''
            this.init()
        },
        init() {
            this.page.pageNo = 1
            this.getList()
        },
        getUrl(val) {
            switch (val) {
                case 'notice':
                    return 'help/HelpNotice/queryHelpNoticePage'
                default:
                    return 'sys/sysSendMessageRecord/querySysPushMessageConfigPage'
            }
        },
        getList() {
            console.log('1111', this.type);
            let url = this.getUrl(this.type)
            this.loading = true
            let cnd = []
            if (this.searchText) {
                cnd.push(
                    {
                        name: this.type === 'notice' ? 'content' : 'sendContent',
                        op: 'like',
                        value: this.searchText
                    }
                )
            }
            this.$api.post(url, {
                sort: {
                    updatedAt: 'desc'
                },
                page: this.page,
                conditionList: cnd
            })
                .then(res => {
                    if (res.success) {
                        this.list = res.data.records || []
                    }
                    this.total = res.data.totalCount
                })
                .finally(() => {
                    this.loading = false
                    this.$store.dispatch('getUnread')
                })
        },
        pageChange(val) {
            this.right.page.pageNo = val
            this.getList()
        },
        setReadAll() {
            this.$store.commit('confirm', {
                content: '确定全部设置为已读吗？',
                callback: (next, back) => {
                    this.$api.get('sys/sysSendMessageRecord/updateReadFlag')
                        .then(res => {
                            if (res.success) {
                                this.$store.commit('success', '设置成功')
                                this.list = this.list.map(item => {
                                    item.readFlag = '1'
                                    return item
                                })
                                next()
                            }
                            this.$store.dispatch('getUnread')
                        })
                        .finally(back)
                }
            })
        },
    }
}
</script>